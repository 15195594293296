.container{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    padding: 12px 16px 12px 16px;
    background: rgb(38, 41, 62);
    .container_info{
      display: flex;
      gap: 12px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .container_name{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        span{
          border-radius: 40px;
          min-width: 44px;
          min-height: 44px;
        }
        p{
          margin-left: 8px;
        }
      }
      .reward_button{
        max-width: 110px;
        max-height: 40px;
      }
      .timer_text{
        color: rgb(255, 255, 255);
        font-family: var(--font-ttsupermolotneue);
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0%;
        text-align: left;
        text-transform: uppercase;
      }
      .timer_label{
        color: rgba(238, 238, 238, 0.5);
        font-family: var(--font-ttsupermolotneue);
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0%;
        text-align: right;
        text-transform: uppercase;
      }
      
    }
    .date_circle{
      min-width: 24px!important; 
      min-height: 24px!important;
      height: 24px!important;
      width: 24px!important; 
      
    }
    .date_circle_outline{
      width: 24px!important; 
      height: 24px!important;
      position: absolute;
    }
  }