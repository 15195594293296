// Функция, которая высчитывает размер шрифта в зависимости от размера экрана
@function calcAdaptiveSize($f-min, $f-max, $v-min, $v-max, $units: px) {
    $k: ($f-max - $f-min)/ ($v-max - $v-min);
    $b: $f-min - $k * $v-min;
  
    $b: $b;
  
    @return calc(#{$k} * 100vw + #{$b} + 1px);
  }
  
  // Миксин использующий функцию calcFluidFontSize, с минимальным и максимальным ограничением по высоте
  @mixin AdaptiveSize($style, $f-min, $f-max, $w-min, $w-max, $fallback: false) {
    #{$style}: $f-min;
  
    @media (min-width: $w-min) {
      @if ($fallback) {
        #{$style}: $fallback;
      }
      #{$style}: calcAdaptiveSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
      #{$style}: $f-max;
    }
  }

 // Миксин для медиа запросов
  @mixin for-size($size) {
    @if $size == phone-only {
      @media (max-width: 599px) { @content; }
    } @else if $size == tablet-portrait-up {
      @media (min-width: 600px) { @content; }
    } @else if $size == tablet-landscape-up {
      @media (min-width: 900px) { @content; }
    } @else if $size == desktop-up {
      @media (min-width: 1200px) { @content; }
    } @else if $size == big-desktop-up {
      @media (min-width: 1800px) { @content; }
    }
  }