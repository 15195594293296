.main_price {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
  }

  .container_price {
    backdrop-filter: blur(10px);
    padding: 4px 2px 4px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #0c1027;
    height: 100%;
    svg {
      height: 10px;
    }
  }

  .price {
    color: rgb(211, 248, 90) !important;
    font-family: var(--font-ttsupermolotneue);
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0%;
    text-align: right;
    text-transform: uppercase;
    padding: 0 4px;
  }
}
