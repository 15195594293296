@use '../../../../styles/utils' as util;

.container {
  position: static;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  flex: none;
  order: 1;
  min-width: 328px;
  max-width: 100%;
  flex-grow: 0;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  background: #181c32;
  font-family: var(--font-ttsupermolotneue);
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;

    @media (max-width: 550px) {
      padding: 16px;
      min-width: auto;
      @media (max-width: 450px) {
        width: 100%;
        min-width: auto;
      }
    }
  }
  .container_head {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .container_part_info {
    display: flex;
    align-items: center;
  }

  .info_time {
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    font-family: var(--font-ttsupermolotneue);
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    padding: 4px 12px 4px 12px;
    background-image: url('../../../../assets/images/wheel/wheelInfo/white/left.svg'),
      url('../../../../assets/images/wheel/wheelInfo/white/right.svg'),
      url('../../../../assets/images/wheel/wheelInfo/white/left_down.svg'),
      url('../../../../assets/images/wheel/wheelInfo/white/right_down.svg');
    background-position:
      left top,
      right top,
      left bottom,
      right bottom;
    background-size: auto;
    background-repeat: no-repeat;
  }

  .container_image {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .prize_bg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .circle_bg {
    position: absolute;
    top: -110%;
    left: 50%;
    transform: translateX(-50%);
  }

  .image_wrapper {
    position: relative;
  }

  .container_weapon_name {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .text_rarity {
      color: rgba(238, 238, 238, 0.5);
      font-family: var(--font-ttsupermolotneue);
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .text_weapon_name {
      font-family: var(--font-ttsupermolotneue);
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase;
    }

    .text_skin_name {
      color: rgb(255, 255, 255);
      font-family: var(--font-ttsupermolotneue);
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .winnerBtn {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    .reward_button:disabled {
      max-height: 48px;
      background: #26293e;
      color: white;
      box-shadow: none;
    }
  }
}

.container_button {
  display: flex;
  width: 100%;
  margin-top: 20px;

  .button_info {
    background: rgb(25, 31, 64);
    color: #d3f85a;
    box-shadow: none;

    &:hover {
      background: rgb(25, 31, 64);
      color: #d3f85a;
    }
  }
}

.container_user_count {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: baseline;
  margin-top: 20px;
  gap: 6px;

  .user_count_text {
    color: rgb(238, 238, 238);
    font-family: var(--font-ttsupermolotneue);
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
  }

  .user_count_number {
    color: rgb(211, 248, 90);
    font-family: var(--font-ttsupermolotneue);
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
  }
}

.bottom_card_info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  background: #26293e;
  padding: 12px 16px;

  .bottom_card_text {
    color: rgba(238, 238, 238, 0.8);
    text-align: center;
    font-family: var(--font-ttsupermolotneue);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    gap: 4px;
    display: flex;
    flex-direction: column;

    p {
      color: rgba(238, 238, 238, 0.5);
      font-weight: 400;
      font-size: 11px;
    }
  }

  .bottom_card_btn {
    color: var(--Basic-Primary-500, #d3f85a);
    border-radius: 120px;
    border: 4px solid var(--Basic-Neutral-500, #191f40);
    background: var(--Basic-Neutral-400, #252e5c);
    box-shadow: none;
    padding: 8px 16px;

    &:hover {
      color: var(--Basic-Primary-500, #d3f85a);
      border: 4px solid var(--Basic-Neutral-500, #191f40);
      background: var(--Basic-Neutral-400, #252e5c);
      box-shadow: none;
    }
  }
}
