@use '../../../styles/utils' as util;

.container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 16.66667%;
  padding: 25px 25px;

  @media (max-width: 1650px) {
    width: 20%;
  }
  @media (max-width: 1250px) {
    width: 25%;
  }
  @media (max-width: 900px) {
    width: 33.3333%;
    padding: 25px 15px;
  }
  @media (max-width: 600px) {
    padding: 25px 12px;
    width: 50%;
  }
  align-items: center;

  &:hover {
    & .image {
      transform: scale(1.05);
    }

    & .text {
      color: var(--primary-color);
    }
  }

  .gradientWrapper {
    width: 66%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.8;

    .gradient {
      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      width: 100%;
      min-height: 100%;
      // background: #5049c1;
      filter: blur(40px);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-transform: translateZ(0);
      backface-visibility: hidden;
      perspective: 1000;
      transform: translate3d(0, 0, 0);
      transform: translateZ(0);
    }
    @media (max-width: 600px) {
      width: 65%;
      max-height: 65%;
    }
  }

  .text {
    color: white;
    font-family: var(--font-ttsupermolotneue);
    @include util.AdaptiveSize(font-size, 12px, 16px, 377px, 1025px, 17px);
    text-transform: uppercase;
    line-height: 1.3;
    font-weight: 600;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    transition: color 0.3s ease;
  }

  .image {
    position: absolute;
    transition: transform 0.3s ease;
  }

  .badge {
    position: absolute;
    right: 0;
    transform: skew(-16deg);
    z-index: 2;
    border-radius: 5px;

    .badge_text {
      color: #0c1027;
      transform: skew(16deg);
      text-align: center;
      padding: 4px 12px;
      font-family: var(--font-ttsupermolotneue);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;
      letter-spacing: 0.56px;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 10px;
        padding: 4px 8px;
      }
    }
  }

  .buttonWithTextContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    width: 100%;
    // @media (max-width: 1100px) {
    //   width: 100%;
    // }

    .buttonWithTextContainer__button {
      align-self: center;
      width: fit-content;
      padding: 12px 24px;
      svg{
        height: 9px;
      }
    }

    .buttonWithTextContainer__price {
      // font-size: 24px;

      @include util.AdaptiveSize(font-size, 20px, 22px, 377px, 1025px);
      // line-height: 30px;
      line-height: 28px;
      font-family: var(--font-arame);
    }
  }

  .countContainer {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .countContainer__countText {
      font-weight: 400;
      font-family: var(--font-arame);
      font-size: 13px;
      @media (max-width: 600px) {
        font-size: 12px;
      }
      line-height: 1.5;
      font-weight: 600;
      color: #939bc2;
    }
  }
}

.img {
  position: relative;
  // пока убрал, оно странно выглядит
  // image-rendering: pixelated;
  width: 100%;
  object-fit: cover;
  margin-bottom: -8px;
  padding-bottom: 100%;
  height: 0;
}

.price_container{
  gap: 4px;
}