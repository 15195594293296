.container_participant{
  display: flex;
  align-items: center;
  color: #d2f85a;
  font-family: var(--font-ttsupermolotneue);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0%;
  text-align: left;
  padding: 4px 12px 4px 12px;
  background-image: url("../../../assets/images/wheel/wheelInfo/green/left.svg"),
  url("../../../assets/images/wheel/wheelInfo/green/right.svg"),
  url("../../../assets/images/wheel/wheelInfo/green/left_down.svg"),
  url("../../../assets/images/wheel/wheelInfo/green/right_down.svg");
  background-position: left top, right top, left bottom, right bottom;
  background-repeat: no-repeat;
  text-shadow: 0px 0px 20px rgba(131, 255, 120, 0.40);
  background-size: 6px;
}